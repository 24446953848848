import $ from 'jquery';
import Swiper, {Navigation} from 'swiper';

import 'swiper/swiper.scss';  
//@import '~swiper/modules/pagination/pagination'; 
import 'swiper/modules/navigation/navigation.scss'; 
import './../../scss/blocks/block-categories.scss';

$(document).ready(function() {

  var slides = document.getElementsByClassName("categories-slider-wrap");

  for (var i = 0; i < slides.length; i++) {
    //Distribute(slides.item(i));
    var categoriesswiper = new Swiper(slides.item(i).getElementsByClassName('categories-swiper').item(0), {
      modules: [Navigation],
      spaceBetween: 16,
      loop: false,
      slidesPerView: "auto",
      navigation: {
        nextEl: slides.item(i).getElementsByClassName('categories-swiper-button-next').item(0),
        prevEl: slides.item(i).getElementsByClassName('categories-swiper-button-prev').item(0),
      },
    });  
  }
  
  

})